import React from 'react';
import SchoolsContext from './SchoolsContext';

class SchoolsProvider extends React.Component {
    state = {
        data: [
            {
                id: 1,
                name: 'Scoala 179',
                studentsNumber: 300,
                teachersNumber: 15
            },
            {
                id: 2,
                name: 'Scoala 72',
                studentsNumber: 120,
                teachersNumber: 28
            },
            {
                id: 3,
                name: 'Scoala Mihai Eminescu',
                studentsNumber: 120,
                teachersNumber: 28
            },
            {
                id: 4,
                name: 'Scoala 179',
                studentsNumber: 120,
                teachersNumber: 28
            },
            {
                id: 5,
                name: 'Scoala 179',
                studentsNumber: 120,
                teachersNumber: 28
            },
            {
                id: 6,
                name: 'Scoala 179',
                studentsNumber: 120,
                teachersNumber: 28
            },
            {
                id: 7,
                name: 'Scoala 179',
                studentsNumber: 120,
                teachersNumber: 28
            },
            {
                id: 8,
                name: 'Scoala 179',
                studentsNumber: 120,
                teachersNumber: 28
            },
            {
                id: 9,
                name: 'Scoala 179',
                studentsNumber: 300,
                teachersNumber: 15
            },
            {
                id: 10,
                name: 'Scoala 179',
                studentsNumber: 120,
                teachersNumber: 28
            },
            {
                id: 11,
                name: 'Scoala 179',
                studentsNumber: 120,
                teachersNumber: 28
            },
            {
                id: 12,
                name: 'Scoala 179',
                studentsNumber: 120,
                teachersNumber: 28
            },
            {
                id: 13,
                name: 'Scoala 179',
                studentsNumber: 120,
                teachersNumber: 28
            },
            {
                id: 14,
                name: 'Scoala 179',
                studentsNumber: 120,
                teachersNumber: 28
            },
            {
                id: 15,
                name: 'Scoala 179',
                studentsNumber: 120,
                teachersNumber: 28
            },
            {
                id: 16,
                name: 'Scoala 179',
                studentsNumber: 120,
                teachersNumber: 28
            }
        ]
    }

    render() {
        return (
            <SchoolsContext.Provider
                value={{
                    data: this.state.data,
                }}
            >
                {this.props.children}
            </SchoolsContext.Provider>
        );
    }
}

export default SchoolsProvider;