import React, {Suspense, lazy} from 'react';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import {
    BrowserRouter,
    Switch,
    Route
} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import Loader from 'react-loader-spinner';
/* Components */
import SchoolsProvider from './Context/SchoolsProvider';
import Layout from "./Pages/Layout/Layout";

const SignIn = lazy(() => import('./Pages/Onboarding/SignIn'));
const Licenses = lazy(() => import('./Pages/Licenses/Licenses'));
const Holidays = lazy(() => import('./Pages/Holidays/Holidays'));
const Modules = lazy(() => import('./Pages/Modules/Modules'));
const TransitionToNextYear = lazy(() => import('./Pages/TransitionToNextYear'));
const ClassTypes = lazy(() => import('./Pages/ClassTypes/ClassTypes'));
const EditClassType = lazy(() => import('./Pages/ClassTypes/EditClassType'));
const License = lazy(() => import('./Pages/Licenses/License/License'));
const LicensesAdd = lazy(() => import('./Pages/Licenses/License/AddLicense'));
const AddTerms = lazy(() => import('./Pages/Terms/AddTerms'));
const AddPolicy = lazy(() => import('./Pages/Terms/AddPolicy'));
const EditTerms = lazy(() => import('./Pages/Terms/EditTerms'));
const Requests = lazy(() => import('./Pages/RegisterRequests/Requests'));
const DeleteRequests = lazy(() => import('./Pages/DeleteRequests/DeleteRequests'));
const Schools = lazy(() => import('./Pages/Schools/Schools'));
const School = lazy(() => import('./Pages/Schools/School/School'));
const SchoolDetails = lazy(() => import('./Pages/Schools/School/SchoolDetais'));
const Students = lazy(() => import('./Pages/Students/Students'));
const Teachers = lazy(() => import('./Pages/Teachers/Teachers'));
const MyProfile = lazy(() => import('./Pages/MyProfile/MyProfile'));
const InnerHome = lazy(() => import('./Pages/Schools/InnerPages/InnerHome/InnerHome'));

// const Eror404 = lazy(() => import('./Pages/Error404'));

class App extends React.Component {
    render() {
        const key = localStorage.getItem("key");
        return (
            <div className='App'>
                <BrowserRouter>
                    <Suspense
                        fallback={
                            <Loader
                                className='loader'
                                type='Circles'
                                color='#00BFFF'
                                height={80}
                                width={80}
                                timeout={3000}
                            />
                        }
                    >
                        <ToastContainer/>
                        <SchoolsProvider>
                            <Switch>
                                {
                                    key ?
                                        <Layout>

                                            <Route path='/' exact component={Schools}/>
                                            <Route path='/register-requests' exact component={Requests}/>
                                            <Route path='/delete-requests' exact component={DeleteRequests}/>
                                            <Route path='/teachers' exact component={Teachers}/>
                                            <Route path='/students' exact component={Students}/>
                                            <Route path='/myProfile' exact component={MyProfile}/>
                                            <Route path='/editSchool/:id' exact component={InnerHome}/>
                                            <Route path='/school/:id' exact component={School}/>
                                            <Route path='/school/details/:id' exact component={SchoolDetails}/>
                                            <Route path='/holidays' exact component={Holidays}/>
                                            <Route path='/modules' exact component={Modules}/>
                                            <Route path='/next-year' exact component={TransitionToNextYear}/>
                                            <Route path='/licenses' exact component={Licenses}/>
                                            <Route path='/class-types' exact component={ClassTypes}/>
                                            <Route path='/class-types/:id' exact component={EditClassType}/>
                                            <Route path='/class-types/add' exact component={ClassTypes}/>
                                            <Route path='/licenses/:id' exact component={License}/>
                                            <Route path='/licenses-add' exact component={LicensesAdd}/>

                                            <Route path='/school/:id/terms/add' exact component={AddTerms}/>
                                            <Route path='/school/:id/policy/add' exact component={AddPolicy}/>
                                            <Route path='/school/:id/terms/:terms_id' exact component={EditTerms}/>
                                        </Layout>
                                        :
                                        <Route path='/' exact component={SignIn}></Route>

                                }
                            </Switch>
                            {/* <Route path='/error-404' exact component={Eror404} /> */}
                            {/* <Route component={Eror404} /> */}
                        </SchoolsProvider>
                    </Suspense>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
