import React, {useState} from 'react';

// style
import './Layout.scss'

import Header from './Header/Header';
import SideDrawer from './SideDrawer/SideDrawer';
import Backdrop from './SideDrawer/Backdrop';

const Layout =({children}) =>{

        const [sideDrawerOpen, setSideDrawerOpen] = useState(false)
        return (
            <div className="layout">
                <Header
                    setSideDrawerOpen={setSideDrawerOpen}
                    sideDrawerOpen={sideDrawerOpen}
                />
                <SideDrawer
                    sideDrawerOpen={sideDrawerOpen}
                   setSideDrawerOpen={setSideDrawerOpen}
                />
                    {
                            sideDrawerOpen &&
                        <Backdrop setSideDrawerOpen={setSideDrawerOpen} />
                    }
                <div>
                    {children}
                </div>
            </div>
        )
   /* }*/
}

export default Layout;
