import React from 'react';
// style
import './Header.scss';
// components
import {Row, Col} from 'react-bootstrap';
import {ReactComponent as Logo} from '../../../Assets/icons/Logo-Tinker.svg';
import {NavLink} from "react-router-dom";
import {GiHamburgerMenu} from "react-icons/all";

class Header extends React.Component {
  render() {
    return (
      <header id='header' className='container-fluid'>
        <div className='header-container-col'>
        <Row className='align-items-center'>
          <Col  className='d-flex justify-content-start'>
            <GiHamburgerMenu
              id='menu-icon'
              className='mr-4' 
              onClick={() => {
                  this.props.setSideDrawerOpen(!this.props.sideDrawerOpen)
              }}
            />
            <NavLink to={"/"}>
              <Logo id='header-logo'/>
            </NavLink>
          </Col>

          <Col className='d-flex justify-content-end'>

          </Col>
        </Row>
        </div>
      </header>
    )
  }
}

export default Header
