import React, {useEffect, useState} from 'react';
// style
import './SideDrawer.scss';
// components
import {Badge, Icon} from "rsuite";
import {NavLink} from "react-router-dom";
import Axios from "axios";

const SideDrawer = (props) => {

    const [registerRequests, setRegisterRequests] = useState(0)
    const [deleteRequests, setDeleteRequests] = useState(0)

    useEffect(() => {
        getRequests()
    }, []);
    useEffect(() => {
        getRequests()
    }, [props.sideDrawerOpen]);

    const getRequests = async () => {
        let response = await Axios.post(
            process.env.REACT_APP_STRAPI_URL + "/intern/requests/count",
            {key: localStorage.getItem("key")}
        );

        if (response && response.data) {
            setRegisterRequests(response.data.register ?? 0)
            setDeleteRequests(response.data.delete ?? 0)
        }

    }
    return (
        <nav className={`side-drawer p-3 ${props.sideDrawerOpen ? 'open' : undefined}`}>
            <div className="d-flex flex-column justify-content-center mt-5 navigation-elements">
                <NavLink to={"/"}
                         className="menu-item"
                         onClick={() => {
                             props.setSideDrawerOpen(false)
                         }}
                >
                    <Icon
                        className={"icon"}
                        icon="building"
                    />
                    <p className={`font-nunito-bold`}
                    >
                        Școli
                    </p>
                </NavLink>
                <NavLink to={"/class-types"}
                         className="menu-item"
                         onClick={() => {
                             props.setSideDrawerOpen(false)
                         }}
                >
                    <Icon
                        className={"icon"}
                        icon="building"
                    />
                    <p className={`font-nunito-bold`}
                    >
                        Discipline
                    </p>
                </NavLink>
                <NavLink to={"/holidays"}
                         className="menu-item"
                         onClick={() => {
                             props.setSideDrawerOpen(false)
                         }}
                >
                    <Icon
                        className={"icon"}
                        icon="calendar"
                    />
                    <p className={`font-nunito-bold`}
                    >
                        Vacanțe
                    </p>
                </NavLink>
                <NavLink to={"/modules"}
                         className="menu-item"
                         onClick={() => {
                             props.setSideDrawerOpen(false)
                         }}
                >
                    <Icon
                        className={"icon"}
                        icon="calendar"
                    />
                    <p className={`font-nunito-bold`}
                    >
                        Module
                    </p>
                </NavLink>

                <NavLink to={"/next-year"}
                         className="menu-item"
                         onClick={() => {
                             props.setSideDrawerOpen(false)
                         }}
                >
                    <Icon
                        className={"icon"}
                        icon="calendar"
                    />
                    <p className={`font-nunito-bold`}
                    >
                        Trecere în următorul an
                    </p>
                </NavLink>
                <NavLink to={"/licenses"}
                         className="menu-item"
                         onClick={() => {
                             props.setSideDrawerOpen(false)
                         }}
                >
                    <Icon
                        className={"icon"}
                        icon="key"
                    />
                    <p>
                        Licențe
                    </p>
                </NavLink>

                <NavLink to={"/register-requests"}
                         className="menu-item"
                         onClick={() => {
                             props.setSideDrawerOpen(false)
                         }}
                >
                    <Icon
                        className={"icon"}
                        icon="user-plus"
                    />
                    <p>
                        <Badge content={registerRequests}> Cereri creare cont</Badge>
                    </p>
                </NavLink>

                <NavLink to={"/delete-requests"}
                         className="menu-item"

                         onClick={() => {
                             props.setSideDrawerOpen(false)
                         }}
                >
                    <Icon
                        className={"icon"}
                        icon="trash-o"
                    />
                    <p>
                        <Badge content={deleteRequests}>  Cereri ștergere cont</Badge>
                    </p>
                </NavLink>

            </div>
        </nav>
    )

}

export default SideDrawer
